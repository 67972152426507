


























































import { API } from "@/shared/api";
import { GlobalEvents } from "@/shared/GlobalEvents";
import { ssmHttpService } from "@/shared/services/http-service";
import { JwtService } from "@/shared/services/JwtService";
import { VueInstanceService } from "@/shared/services/vue-instance-service";
import usuarioModule from "@/store/modules/usuario-module";
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    ChangePwd: () => import("@/views/usuarios/ChangePwd.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue"),
  },
  $_veeValidate: { validator: "new" },
})
export default class MiPerfil extends Vue {
  public shwchangepwd = false;
  public fichero!: File;
  public date = 2;

  public created() {
    usuarioModule.getmiusuario();
  }

  public get datasource() {
    return usuarioModule.mi_usuario;
  }

  public cancelar() {
    usuarioModule.getmiusuario();
  }

  public change_pwd() {
    this.shwchangepwd = true;
  }

  /**
   * aceptar
   */
  public aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        usuarioModule
          .modificarmi_usuario(this.datasource)
          .then(() => this.submitFile());
      }
    });
  }

  submitFile() {
    /*Initialize the form data*/
    let formData = new FormData();

    /*Add the form data we need to submit*/
    formData.append("file", this.fichero);

    /*Make the request to the POST /single-file URL*/
    ssmHttpService
      .postfile(API.usuario + "/image", formData)
      .then(function() {
        document.location.reload(true);
      })
      .catch(function() {});
  }
  public get getImagen() {
    return (
      API.webApiBaseImages +
      JwtService.jwtDecode().nameid +
      ".png?rnd=" +
      this.date
    );
  }
}
